<template>
  <b-row>
    <b-col cols="12">
      <div>
        <form-wizard
          color="#b9cde5"
          :title="null"
          :subtitle="null"
          shape="square"
          finish-button-text="Guardar Muestreo"
          back-button-text="Seleccionar Jaulas"
          next-button-text="Siguiente"
          class="mb-3"
          @on-complete="formSubmitted"
        >
          <!-- seleccion de jaulas tab-->
          <tab-content
            title="Selección de Jaulas"
            :before-change="validationForm"
          >
            <!-- centros -->
            <validation-observer ref="centroRules" tag="form">
              <b-form-group id="fill">
                <label class="h3" label-for="centro">Centro</label>
                <validation-provider
                  #default="{ errors }"
                  name="centro"
                  rules="required"
                >
                  <b-form-select
                    id="centro"
                    class="w-100 mr-1"
                    v-model="selected"
                    :options="options"
                    :state="errors.length > 0 ? false : null"
                  />
                </validation-provider>
              </b-form-group>
            </validation-observer>
            <!-- jaulas -->
            <b-row class="match-height mt-3">
              <b-col>
                <h3>Seleccionar Jaulas</h3>
              </b-col>
            </b-row>
            <b-row class="justify-content-md-center">
              <b-col
                v-for="(jaula, index) in listaJaulas"
                :key="index"
                sm="6"
                md="4"
                xl="3"
                :id="[`jaula${index + 1}`]"
                :class="{ jaula: active_jaula, 'w-100': true, 'h-100': true }"
              >
                <b-row>
                  <b-col>
                    <input
                      type="checkbox"
                      class="jaula-contenido d-md-flex"
                      v-model="jaulas_selected"
                      :id="'jaula_' + jaula.id"
                      :value="jaula"
                      :disabled="hasRecords(jaula.id)"
                      @click="selectJaula(jaula.id)"
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="text-center mt-2">
                    <label :for="'jaula' + jaula.id" class="font-jaula">
                      {{ jaula.nombre }}
                    </label>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </tab-content>

          <!-- muestreo de jaulas tab -->
          <tab-content title="Muestreo de Jaulas">
            <b-row>
              <b-col>
                <h3>Muestreo</h3>
                <p>Ingresa datos en la columna correspondiente según Jaula.</p>
              </b-col>
            </b-row>
            <b-row>
              <!-- jaulas -->
              <b-col cols="2" class="text-center lista-jaulas">
                <b-list-group-item
                  :active="item == itemSelected"
                  button
                  variant="outline-secondary"
                  @click="select(item)"
                  :key="index"
                  v-for="(item, index) in jaulas_selected"
                >
                  {{ item.nombre }}
                </b-list-group-item>
              </b-col>
              <!-- registros -->
              <b-col cols="10">
                <b-form ref="form" class="repeater-form">
                  <b-table-simple sticky-header>
                    <b-thead>
                      <b-tr>
                        <b-th>Numero Registro</b-th>
                        <b-th>Peso (grs)</b-th>
                        <b-th>Lh (cm)</b-th>
                        <b-th>Lesión SRS</b-th>
                        <b-th>Lesión Caligus</b-th>
                        <b-th>Lesión Lobo</b-th>
                        <b-th>Madurez</b-th>
                        <b-th>Branquias</b-th>
                        <b-th>Nuevo Defecto</b-th>
                        <b-th>Acciones</b-th>
                        <b-th></b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody
                      v-for="(item, index) in datosJaulas"
                      v-bind:key="index"
                    >
                      <b-tr v-if="item.jaula_id === itemSelected.id">
                        <b-td>
                          <b-form-group class="w-form">
                            <b-form-input
                              id="registro"
                              v-model="item.numero_registro"
                              type="number"
                              placeholder="Numero de Registro"
                            />
                          </b-form-group>
                        </b-td>
                        <b-td>
                          <b-form-group class="w-form">
                            <b-form-input
                              id="peso"
                              v-model="item.peso"
                              type="number"
                              placeholder="peso"
                            />
                          </b-form-group>
                        </b-td>
                        <b-td>
                          <b-form-group class="w-form">
                            <b-form-input
                              id="lh"
                              v-model="item.lh"
                              type="number"
                              placeholder="lh cm"
                            />
                          </b-form-group>
                        </b-td>
                        <b-td>
                          <b-form-group class="w-form">
                            <b-form-select
                              id="srs"
                              v-model="item.lesion_srs"
                              :options="listaLesiones"
                            />
                          </b-form-group>
                        </b-td>
                        <b-td>
                          <b-form-group class="w-form">
                            <b-form-select
                              id="caligus"
                              v-model="item.lesion_caligus"
                              :options="listaLesiones"
                            />
                          </b-form-group>
                        </b-td>
                        <b-td>
                          <b-form-group class="w-form">
                            <b-form-select
                              id="lobo"
                              v-model="item.lesion_lobo"
                              :options="listaLesiones"
                            />
                          </b-form-group>
                        </b-td>
                        <b-td>
                          <b-form-group class="w-form">
                            <b-form-select
                              id="madurez"
                              v-model="item.madurez"
                              :options="listaMadurez"
                            />
                          </b-form-group>
                        </b-td>
                        <b-td>
                          <b-form-group class="w-form">
                            <b-form-select
                              id="branquias"
                              v-model="item.branquias"
                              :options="listaBranquias"
                            />
                          </b-form-group>
                        </b-td>
                        <b-td>
                          <b-form-group class="w-form">
                            <b-form-input
                              id="defecto"
                              v-model="item.nuevo_defecto"
                              type="text"
                              placeholder="Añadir nuevo defecto"
                            />
                          </b-form-group>
                        </b-td>

                        <b-td>
                          <b-form-file
                            v-model="item.media"
                            :state="Boolean(item.media)"
                            accept=".jpg, .png"
                            size="sm"
                            style="top: -8px; padding: 0% 58%"
                            browse-text="Adjuntar"
                            class="adjunto"
                          ></b-form-file>

                          <b-button
                            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                            variant="flat-danger"
                            class="btn-icon"
                            @click="removeItem(index)"
                          >
                            <feather-icon icon="Trash2Icon" />
                          </b-button>
                        </b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-form>
                <b-button
                  class="add-registro"
                  
                  :disabled="itemSelected == null ? true : false"
                  @click="repeateAgain"
                >
                  <feather-icon icon="PlusIcon" />
                  <span>Agregar Registro</span>
                </b-button>
              </b-col>
            </b-row>
          </tab-content>
        </form-wizard>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormCheckbox,
  BButton,
  BListGroupItem,
  BFormFile,
  BTable,
  BTableSimple,
  BTbody,
  BThead,
  BTfoot,
  BTr,
  BTd,
  BTh,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import { ref } from "@vue/composition-api";

export default {
  components: {
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormCheckbox,
    BButton,
    BListGroupItem,
    BFormFile,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BTable,
    BTableSimple,
    BTbody,
    BThead,
    BTfoot,
    BTr,
    BTd,
    BTh,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      selected: null,
      activated_jaula: false,
      active_jaula: true,

      jaulas_selected: [],
      itemSelected: null,
      // name: "",
      required,
      image: null,
      options: [
        { value: null, text: "Seleccione Centro" },
        { value: "1", text: "Centro 1" },
        { value: "2", text: "Centro 2" },
        { value: "3", text: "Centro 3" },
      ],
      listaJaulas: [
        { id: "1", nombre: "Jaula 1" },
        { id: "2", nombre: "Jaula 2" },
        { id: "3", nombre: "Jaula 3" },
        { id: "4", nombre: "Jaula 4" },
        { id: "5", nombre: "Jaula 5" },
        { id: "6", nombre: "Jaula 6" },
        { id: "7", nombre: "Jaula 7" },
        { id: "8", nombre: "Jaula 8" },
        { id: "9", nombre: "Jaula 9" },
        { id: "10", nombre: "Jaula 10" },
        { id: "11", nombre: "Jaula 11" },
        { id: "12", nombre: "Jaula 12" },
      ],
      listaLesiones: [
        { value: "0", text: "Sin Lesión" },
        { value: "1", text: "Lesiones Leves" },
        { value: "2", text: "Lesiones Medias" },
        { value: "3", text: "Lesiones Evidentes" },
      ],
      listaMadurez: [
        { value: "0", text: "Sin Madurez" },
        { value: "1", text: "Madurez Leves" },
        { value: "2", text: "Madurez Medias" },
        { value: "3", text: "Madurez Evidentes" },
      ],
      listaBranquias: [
        { value: "0", text: "Branquias Normales" },
        { value: "1", text: "Branquias Daño Leves" },
        { value: "2", text: "Branquias Daño Medias" },
        { value: "3", text: "Branquias Daño Evidentes" },
      ],
      datosJaulas: [],
    };
  },

  methods: {
    selectJaula(jaula_id) {
      let jaula = document.getElementById("jaula" + jaula_id);
      let checkbox_jaula = document.getElementById("jaula_" + jaula_id);

      console.log(jaula);
      console.log(this.jaulas_selected);
      if (checkbox_jaula.checked == true) {
        jaula.classList.add("jaula_seleccionada");
        console.log(jaula);
        console.log("checked true");
      } else {
        jaula.classList.remove("jaula_seleccionada");
        console.log(jaula);
        console.log("checked false");
      }
    },
    // comprueba si datosJaula contiene alguna de la Id de jaula, si es true el checkbox queda deshabilitado
    hasRecords(obj) {
      return this.datosJaulas.some(function (t) {
        return t.jaula_id === obj;
      });
    },
    select(item) {
      console.log(item);
      this.itemSelected = item;
    },
    formSubmitted() {
      console.log("Centro seleccionado: ", this.selected);
      console.log("Jaulas seleccionadas", this.jaulas_selected);
      console.log("Muestreo: ", this.datosJaulas);

      //Componente para notificacion por mail, vista NotificacionesMuestreo

      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Form Submitted",
          icon: "EditIcon",
          variant: "success",
        },
      });
    },

    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.centroRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    repeateAgain() {
      this.datosJaulas.push({
        jaula_id: this.itemSelected.id,
        numero_registro: "",
        peso: "",
        lh: "",
        lesion_srs: "",
        lesion_caligus: "",
        lesion_lobo: "",
        madurez: "",
        branquias: "",
        nuevo_defecto: "",
        media: null,
      });
    },
    removeItem(index) {
      this.datosJaulas.splice(index, 1);
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";

.jaula {
  border: 1px solid #b9cde5;
  background-color: #b9cde5;
  max-width: 300px;
  font-size: 100%;
  height: 150px !important;
  align-items: left;
  justify-content: left;
  padding: 20px;
  margin-bottom: 20px;
  margin-top: 20px;
  margin-left: 2px;
  margin-right: 2px;

  font-weight: bold;
}

.font-jaula {
  font-size: 20px;
  color: #ffffff;
}

.jaula-contenido {
  height: 20px;
  width: 20px;
  font-size: 28px !important;
  text-align: left;
}

.jaula_seleccionada {
  border: 1px solid #e26629 !important;
  background-color: #e26629 !important;
  max-width: 300px;
  font-size: 100%;
  height: 150px !important;
  align-items: left;
  justify-content: left;
  padding: 20px;
  margin-bottom: 20px;
  margin-top: 20px;
  margin-left: 2px;
  margin-right: 2px;
  font-weight: bold;
}

.nombreJaula {
  margin-top: 10px;
}
// margin button
.m-button {
  margin: 5% 0%;
}
.add-registro {
  margin-top: 10px;
}
.repeater-form {
  padding-top: 1%;
  transition: 0.35s height;
  max-height: 500px;
  overflow-x: auto;
  overflow-y: auto;
  white-space: nowrap;
  // ancho form grup input muestreo
  .w-form {
    width: 250px;
  }
}

.lista-jaulas {
  max-height: 400px;
  margin-bottom: 10px;
  overflow-y: auto;

  .list-group-item {
    background-color: #b9cde5;
    color: #ffffff;
  }

  .list-group-item.active {
    border: 1px solid #e26629;
    background-color: #e26629;
    margin: 5% 0%;
    border-radius: 4px;
  }

  .list-group-item + .list-group-item {
    border-top-width: 1px;
    margin: 5% 0%;
    border-radius: 4px;
  }
  .list-group-item:first-child {
    border-radius: 4px;
    margin: 5% 0%;
  }
  .list-group-item:hover {
    background-color: #9bb4d3;
  }
}

// aplicando estilo final PASO 1

#step-SeleccióndeJaulas0 > div {
  background-color: #b9cde5 !important;
}

#step-MuestreodeJaulas2 {
  background-color: #e26629 !important;
  .wizard-icon {
    color: #ffffff;
  }
}
// aplicando estilo final PASO 2
.table-b-table-default {
  background-color: #003d7e !important;
  color: #ffffff !important;
}

// segundo paso + agregar registro

.add-registro {
  background-color: #003d7e !important;
  color: #ffffff !important;
  border-color: #ffffff !important;
}

.add-registro:hover{
   box-shadow: 0 8px 25px -8px #003d7e;
}

.add-registro:active{
  background-color: #003d7e !important ;
}

.add-registro:focus{
  background-color: #003d7e !important ;
}


//botón Siguiente
.vue-form-wizard .wizard-card-footer .wizard-footer-right .wizard-btn {
  background-color: #9cacb1 !important;
  color: #ffffff !important;
  border-color: #9cacb1 !important ;
}

.vue-form-wizard .wizard-card-footer .wizard-footer-right .wizard-btn:hover {
  box-shadow: 0 8px 25px -8px #9cacb1;
}

// botón Seleccionar Jaulas
.vue-form-wizard .wizard-card-footer .wizard-footer-left .wizard-btn {
  background-color: #e5e5e5 !important;
  color: #605d5d !important;
  border-color: #e5e5e5 !important ;
}
.vue-form-wizard .wizard-card-footer .wizard-footer-left .wizard-btn:hover {
  box-shadow: 0 8px 25px -8px #9cacb1 !important;
  background-color:#e5e5e5 !important;
}

//boton 1 segundo stage

.vue-form-wizard .wizard-navigation .wizard-nav li .wizard-icon-circle.checked{
  background-color: #e26629 !important;
  .wizard-icon {
    color: #ffffff;
  }
}




</style>
